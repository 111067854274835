import React from "react"
import { Row, Col } from "antd"
import {
  ContainerContent,
  Layout,
  SEO,
  ContainerContentFixed,
  BannerPage,
  QuoteForm,
  Colors
} from "../components"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const QuotePage = ({ data }) => {
  const services = data.allServicesJson.nodes
  return (
    <Layout>
      <SEO
        title="Get Quote"
        description={"Fill in online form to get a quote."}
      />
      <BannerPage
        title={"Get Quote"}
        subTitle={"Fill in our online form below to get a quote"}
      />

      <ContainerContent style={{ background: Colors.White }}>
        <ContainerContentFixed>
          <Row>
            <Col lg={6} />
            <Col lg={12}>
              <QuoteForm services={services} />
            </Col>
          </Row>
        </ContainerContentFixed>
      </ContainerContent>
      <Img
        style={{ width: "100%", height: "250px" }}
        fluid={data["Bg"].childImageSharp.fluid}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    Bg: file(relativePath: { eq: "bg3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allServicesJson {
      nodes {
        id
        name
      }
    }
  }
`

export default QuotePage
